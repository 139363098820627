import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import { CtaGetDemo } from "components/cta"

import { Layout, Seo, Nav } from "components/common"
import { useMeta, device } from "utils"

import {
  IntegrationTemplateHero,
  IntegrationTemplateOverview,
  IntegrationTemplateData,
  IntegrationTemplateContacts,
  IntegrationTestimonials,
  // IntegrationTemplateBestPractices,
} from "src/sections/integrations"

const Integration = ({ data: { integration_data, cta_get_demo } }) => {
  const {
    slug,
    integration,
    logoSmall,
    title,
    subtitle,
    heroImage,
    metaTitle,
    metaDescription,
    metaImage,
    overviewTitle,
    overviewValueProps,
    dataTitle,
    dataValueProps,
    getStartedTitle,
    getStartedContacts,
    testimonials,
    // bestPracticesTitle,
    // bestPracticesBody,
  } = integration_data

  const meta = useMeta({
    metaTitle,
    metaDescription,
    metaImage,
  })

  return (
    <Layout>
      <Seo {...meta} />
      <StyledIntegrations>
        <Nav />

        <IntegrationTemplateHero
          logoSmall={logoSmall}
          slug={slug}
          hat={integration}
          title={title}
          subtitle={subtitle}
          media={heroImage}
        />
        <IntegrationTemplateOverview
          title={overviewTitle}
          valueProps={overviewValueProps}
        />
        <IntegrationTemplateContacts
          title={getStartedTitle}
          valueProps={getStartedContacts}
        />
        <IntegrationTemplateData
          title={dataTitle}
          valueProps={dataValueProps}
        />
        {testimonials && (
          <IntegrationTestimonials testimonials={testimonials} />
        )}

        {/* <IntegrationTemplateBestPractices
          title={bestPracticesTitle}
          body={bestPracticesBody}
        /> */}
        <div className="integrations_ctaWrap">
          <CtaGetDemo {...cta_get_demo} />
        </div>
      </StyledIntegrations>
    </Layout>
  )
}

const StyledIntegrations = styled.div`
  .integrations_ctaWrap {
    margin-top: var(--section-mobile-padding-top);

    @media ${device.laptop} {
      margin-top: var(--section-padding-top);
    }
  }
`

export default Integration

export const query = graphql`
  query IntegrationSlug($slug: String!) {
    integration_data: contentfulIntegration(slug: { eq: $slug }) {
      ...Integration
    }
    cta_get_demo: contentfulSection(sectionId: { eq: "cta_get_demo" }) {
      ...Section
    }
  }
`
